<template>
  <CurrentEventDataProvider v-slot="{ data, designStyles, features }">
    <CategoriesDataProvider v-slot="{ speakersCategories }">
      <div class="flex flex-grow h-full py-2" :style="designStyles">
        <div class="flex flex-col w-full h-full">
          <div class="space-y-2 p-4">
            <div v-if="showHeader" class="hidden md:flex text-md font-bold">
              {{ data.captions.speakersCaption }}
            </div>
            <!-- Filter bar -->
            <tappin-filters
              v-if="features.speakerFilter && !stageFilterDesign"
              ref="filterWc"
              :placeholder="translations.search"
              @filters-search="handleSearchChange"
              showCategoriesFilter
              :categories="JSON.stringify(speakersCategories)"
              @filters-categories="handleChangeCategory"
              selectStyles="background-color: white; color: black;"
              :translations="JSON.stringify(translations)"
            >
            </tappin-filters>
            <div
              v-if="features.speakerFilter && stageFilterDesign"
              class="flex justify-between items-center"
            >
              <Filters
                showSearchFilter
                showCategories
                @search-filter="handleSearchChange"
                @category-filter="handleChangeCategory"
                :categories="speakersCategories"
              />
            </div>
          </div>
          <!-- body -->
          <div class="flex-grow h-auto overflow-x-hidden p-0 md:p-2">
            <div class="text-center" v-if="speakers.length < 1">
              <p>{{ $t('Components.Speaker.SpeakerList.noSpeakers') }}</p>
              <div slot="footer" class="loading-spinner">
                <Spinner v-if="isLoading" />
              </div>
            </div>
            <VirtualList
              v-else
              :class="
                'w-full px-1 overflow-y-auto' +
                (!isStage ? ' list-container' : '')
              "
              :style="`height: ${listHeight}dvh`"
              :data-key="'id'"
              :data-sources="speakers"
              :data-component="speakerDetailsView"
              v-on:tobottom="onScrollToBottom"
            >
              <div slot="footer" class="loading-spinner">
                <Spinner v-if="isLoading" />
              </div>
            </VirtualList>
          </div>
        </div>
      </div>
    </CategoriesDataProvider>
  </CurrentEventDataProvider>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
import VirtualList from 'vue-virtual-scroll-list';
import SpeakerDetails from '@/views/Speaker/SpeakerDetails';
import Spinner from '@/components/shared/Spinner';
import ApiClient from '@/client';
import CategoriesDataProvider from '../providers/CategoriesDataProvider.vue';
import Filters from '../shared/Filters.vue';
export default {
  name: 'Speakers',
  components: {
    VirtualList,
    Spinner,
    Filters
  },
  props: {
    active: Boolean,
    isStage: Boolean,
    showHeader: Boolean,
    listHeight: Number,
    stageFilterDesign: Boolean
  },
  data() {
    return {
      debouncedSearchTimeout: null,
      debouncedSearchText: '',
      page: 1,
      pageSize: 20,
      speakerDetailsView: SpeakerDetails,
      isLoading: false,
      translations: {
        sort_by: this.$t('Components.Filters.sortBy'),
        categories: this.$t('Components.Filters.categories'),
        search: this.$t('Components.Filters.searchPlaceholder')
      }
    };
  },
  computed: {
    ...mapGetters('Speakers', { speakers: 'getItems' }),
    searchText: {
      get() {
        return this.debouncedSearchText;
      },
      set(val) {
        if (this.debouncedSearchTimeout) {
          clearTimeout(this.debouncedSearchTimeout);
        }
        this.debouncedSearchTimeout = setTimeout(async () => {
          if (this.debouncedSearchText?.trim() !== val?.trim()) {
            this.debouncedSearchText = val;
            this.page = 1;
            await this.fetchSpeakers({
              page: this.page,
              pageSize: this.pageSize,
              search: this.debouncedSearchText?.trim(),
              category: this.selectedCategory
            });
          }
        }, 500);
      }
    }
  },
  watch: {
    active: {
      async handler(val) {
        if (val) {
          this.page = 1;
          this.setSpeakers({ data: [], append: false });
          await this.fetchSpeakers({
            page: this.page,
            pageSize: this.pageSize,
            search: this.debouncedSearchText,
            category: this.selectedCategory,
            append: true
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Speakers', ['setSpeakers']),
    async fetchSpeakers(payload) {
      this.isLoading = true;
      const response = await ApiClient.getSpeakers(
        payload.page,
        payload.pageSize,
        payload.search,
        payload.category
      );
      this.setSpeakers({ data: response.data, append: !!payload.append });
      this.isLoading = false;
    },
    async onScrollToBottom() {
      this.page += 1;
      this.fetchSpeakers({
        page: this.page,
        pageSize: this.pageSize,
        search: this.debouncedSearchText,
        category: this.selectedCategory,
        append: true
      });
    },
    handleChangeCategory(value) {
      const val = !this.stageFilterDesign ? value.detail : value;
      this.selectedCategory = val;
      this.page = 1;
      this.fetchSpeakers({
        page: this.page,
        pageSize: this.pageSize,
        search: this.debouncedSearchText,
        category: this.selectedCategory,
        append: false
      });
    },
    handleSearchChange(e) {
      const val = !this.stageFilterDesign ? e.detail : e;
      this.searchText = val;
    }
  }
};
</script>

<style scoped>
.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}
button.skip-letters:focus {
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 0 30%;
  padding: 5px;
  font-size: 20px;
  background: white;
  text-align: center;
  color: inherit;
  z-index: 999;
}
.list-container > :first-child {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  text-align: center;
}

@media (max-width: 600px) {
  .list-container > :first-child {
    grid-template-columns: 1fr;
  }
}
</style>
