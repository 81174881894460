<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'CategoriesDataProvider',
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('Categories', ['fetchItems'])
  },
  computed: {
    ...mapGetters('Categories', [
      'getItems',
      'isLoading',
      'failed',
      'getSponsorsCategories',
      'getAgendaSessionsCategories',
      'getSpeakersCategories'
    ])
  },
  render() {
    return this.$scopedSlots.default({
      categories: this.getItems,
      sponsorsCategories: this.getSponsorsCategories,
      agendaSessionCategories: this.getAgendaSessionsCategories,
      speakersCategories: this.getSpeakersCategories
    });
  }
};
</script>
