<template>
  <div class="absolute top-0 bottom-0 w-full h-full">
    <SpeakerList
      :active="true"
      :isStage="true"
      :showHeader="true"
      :listHeight="80.4"
      :stageFilterDesign="true"
    />
  </div>
</template>
<script>
import SpeakerList from '@/components/speaker/SpeakerList.vue';

export default {
  name: 'Speakers',
  components: {
    SpeakerList
  }
};
</script>

<style scoped>
::v-deep .html-content > p:has(iframe) {
  position: relative;
  padding-top: 56.25%;
}
::v-deep .html-content > p > iframe {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
}
</style>
