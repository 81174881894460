<template>
  <div class="flex flex-col flex-wrap h-full w-full">
    <div class="flex justify-between shrink-0 grow-0 flex-auto">
      <h1 class="font-bold text-md md:text-lg">
        {{ quizTitle ? quizTitle : '' }}
      </h1>
    </div>
    <div class="p-0 md:p-4 flex flex-col">
      <div
        v-if="showAverages"
        class="flex justify-between md:justify-end shrink-0 grow-0 flex-auto py-2"
      >
        <h2 class="md:font-bold text-base">
          {{ quizTitle }} average score:
          {{ averages?.quizAverage }}
        </h2>
      </div>
      <div
        class="flex justify-between md:justify-end shrink-0 grow-0 flex-auto pb-4"
      >
        <h2 v-if="showAverages" class="md:font-bold text-base">
          Total {{ poll.eventName }} average score: {{ averages?.totalAverage }}
        </h2>
      </div>
      <div class="mb-2 shrink-0 grow-0 flex-auto">
        <h2 class="font-bold text-base md:text-md">
          {{ poll.title }}
        </h2>
      </div>

      <div class="flex justify-end shrink-0 grow-0 flex-auto">
        <button class="button toggle-type" @click="toggleChartType">
          Switch chart type
        </button>
      </div>
      <div class="flex chart mt-auto mb-auto">
        <PieChart
          v-if="showPieChart"
          :poll="poll.votes"
          class="justify-center w-full"
        />
        <BarChart
          v-if="showBarChart"
          :poll="poll.votes"
          :all-vote-count="poll.allVoteCount"
          class="w-full"
        />
        <ScatterChart
          v-if="showScatterChart"
          :poll="poll.baseVotes"
          :all-vote-count="poll.allVoteCountBase"
          :participants-count="poll.participantsCount"
          class="w-full"
          :delegationsIds="poll.delegationIds"
          :unanswredsDelegationIds="poll.unansweredDelegationIds"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/polls/BarChart';
import PieChart from '@/components/polls/PieChart';
import ScatterChart from '@/components/polls/ScatterChart';

export default {
  components: {
    BarChart,
    PieChart,
    ScatterChart
  },
  props: {
    poll: Object,
    quizTitle: String,
    averages: Object,
    showAverages: Boolean
  },
  data() {
    return {
      showBarChart: true,
      showPieChart: false,
      showScatterChart: false
    };
  },
  methods: {
    toggleChartType() {
      if (this.showBarChart) {
        this.showBarChart = false;
        this.showPieChart = !this.showPieChart;
      } else if (this.showPieChart) {
        this.showScatterChart = !this.showScatterChart;
        this.showPieChart = false;
      } else {
        this.showBarChart = !this.showBarChart;
        this.showScatterChart = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 1023px) {
  .poll-results {
    .card {
      .toggle-type {
        color: var(--color-secondary-bg);
        opacity: 0.75;
      }
      .chart {
        height: 100%;
      }
    }
  }
}
.poll-results {
  .card {
    .toggle-type {
      color: var(--color-secondary-bg);
      opacity: 0.75;
    }
    .chart {
      height: 100%;
      min-height: calc(100vh - 28em);
    }
  }
}
</style>
