<template>
  <Section id="agenda" container-class="max-w-large anchor">
    <template slot="heading">
      {{ caption }}
    </template>
    <template slot="content">
      <tappin-filters
        ref="filterWc"
        :placeholder="translations.search"
        @filters-search="handleSearchChange"
        showCategoriesFilter
        :categories="JSON.stringify(agendaSessionCategories)"
        @filters-categories="handleChangeCategory"
        selectStyles="background-color: white; color: black;"
        :translations="JSON.stringify(translations)"
      ></tappin-filters>
      <CollapsibleList v-if="!isLoading">
        <CollapsibleItem
          v-slot="{ active, toggle }"
          :item-id="date"
          :agenda="singleAgendaGroupedByDay"
          v-for="(day, date) in displayedItems.sessions"
          :key="date"
        >
          <div
            :class="{
              'border-b': lastDateInSingleAgenda !== date && !active
            }"
            class="py-8"
            style="border-color: #ccc"
          >
            <!-- Day -->
            <button
              style="text-transform: capitalize"
              class="focus:outline-none flex justify-between items-center w-full text-md md:text-lg font-sans"
              @click="toggle"
            >
              <Moment
                v-if="locale === 'en'"
                :value="date"
                format="dddd DD MMMM"
              />
              <Moment
                v-if="locale === 'nb'"
                :value="date"
                format="dddd DD. MMMM"
              />

              <i
                class="font-bold text-md sm:text-lg far"
                :class="{
                  'fa-chevron-up': active,
                  'fa-chevron-down': !active
                }"
              />
            </button>

            <!-- Sessions -->
            <div v-if="active">
              <div class="leader-wrapper text-dark">
                <CollapsibleList>
                  <CollapsibleItem
                    v-slot="{ active, toggle }"
                    v-for="session in day"
                    :item-id="session.id"
                    :key="session.id"
                    :agenda="singleAgendaGroupedByDay"
                  >
                    <Card
                      class="leader-start flex space-x-2"
                      :class="[
                        {
                          indent: searchAgendasForDateAndTime(
                            date,
                            session.startDate
                          ).length
                        },
                        {
                          'indent-first':
                            groupItemIndex(
                              date,
                              session.startDate,
                              session.id
                            ) === 0
                        },
                        {
                          'indent-last': groupItemIsLast(
                            date,
                            session.startDate,
                            session.id
                          )
                        }
                      ]"
                    >
                      <div
                        class="border-4 border-solid"
                        v-if="
                          tracks &&
                          tracks.length > 1 &&
                          session.trackId != '0' &&
                          tracks[0].id != session.trackId
                        "
                        :style="{
                          'border-color': trackById(session.trackId)
                            .headerBackgroundColor
                        }"
                      ></div>
                      <div class="space-y-2 py-1 w-full">
                        <div class="flex justify-between">
                          <div
                            class="text-sm md:text-md font-sans flex items-center"
                          >
                            <div class="inline-block whitespace-no-wrap">
                              <span>
                                {{ session.startDate | moment('HH:mm') }}
                              </span>
                              -
                              <span>
                                {{ session.endDate | moment('HH:mm') }}
                              </span>
                            </div>

                            <div class="ml-4">
                              <div
                                class="inline-block text-xs py-1 px-2 text-white"
                                v-if="
                                  tracks &&
                                  tracks.length > 1 &&
                                  session.trackId != '0' &&
                                  !trackById(session.trackId).hideTrackTitle
                                "
                                :style="{
                                  'background-color': trackById(session.trackId)
                                    .headerBackgroundColor
                                }"
                              >
                                {{ trackById(session.trackId).title }}
                              </div>
                            </div>
                          </div>
                          <div class="flex">
                            <div
                              class="text-red"
                              v-if="session.myAgendaSize > 0"
                            >
                              {{ totalSeatsAvailable(session) }}
                              {{
                                session.myAgendaSize > 1
                                  ? $t('Views.Stage.Agenda.seatsAvailable')
                                  : $t('Views.Stage.Agenda.seatAvailable')
                              }}
                            </div>
                            <!--session booking  loading-->
                            <div v-if="isBookSessionLoading">
                              <i class="fas fa-circle-notch fa-spin text-sm" />
                            </div>
                            <div
                              v-if="isBookingsEnabled && !isBookSessionLoading"
                              class="ml-4"
                            >
                              <BookSessionButton
                                v-if="
                                  !isLimitReached(session) &&
                                  !isSessionPassed(session)
                                "
                                @click="addOrDeleteBooksSesions(session)"
                                class="button space-x-2"
                                :class="[
                                  bookSessions.some(
                                    (ses) => ses.id === session.id
                                  )
                                    ? 'text-red border border-red	'
                                    : 'border'
                                ]"
                                :icon="[
                                  bookSessions.some(
                                    (ses) => ses.id === session.id
                                  )
                                    ? 'minus-square'
                                    : 'calendar-plus-o'
                                ]"
                                :custom_caption="
                                  bookSessions.some(
                                    (ses) => ses.id === session.id
                                  )
                                    ? $t('Views.Stage.Agenda.cancel')
                                    : $t('Views.Stage.Agenda.attend')
                                "
                              />
                              <button
                                class="font-bold text-sm md:text-md fa"
                                v-else
                              >
                                <i class="fa fa-lock"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 class="font-bold text-md">
                            {{ session.title }}
                          </h3>
                          <h4>
                            {{ session.subtitle }}
                          </h4>
                        </div>

                        <div
                          v-if="session.speakers"
                          class="flex flex-row flex-wrap ml-2 md:ml-4"
                        >
                          <div
                            class="flex justify-items items-center space-x-2 space-y-2 w-full md:w-1/5"
                            v-for="speaker in session.speakers"
                            :key="speaker.id"
                          >
                            <i class="fa fa-microphone fa-sm" />
                            <div class="flex flex-col text-sm">
                              <span class="font-bold">{{
                                speaker.name + ' ' + speaker.lastName
                              }}</span>
                              <span v-if="speaker.jobTitle">
                                {{ speaker.jobTitle }}</span
                              >
                              <span v-if="speaker.company">
                                {{ speaker.company }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="py-1" v-show="active">
                          <div
                            class="rich-text html-content"
                            v-html="session.description"
                          ></div>
                        </div>
                        <div class="pt-2">
                          <button
                            v-if="session.description"
                            @click="toggle"
                            class="button button-secondary text-sm md:text-base mr-4"
                          >
                            {{
                              active
                                ? $t('Views.Lobby.Sections.Program.showLess')
                                : $t('Views.Lobby.Sections.Program.moreInfo')
                            }}
                          </button>
                          <button
                            @click="goToStreamFromSession(session)"
                            v-if="session.liveUrl"
                            class="button button-primary text-sm md:text-base"
                          >
                            {{ $t('Views.Lobby.Sections.Program.goToScene') }}
                          </button>
                          <router-link
                            tag="button"
                            :to="{ name: 'stage' }"
                            v-show="!isExternalAgenda"
                            v-else-if="
                              showGoToMainStage &&
                              checkMainStage.options.mainStageEnabled === true
                            "
                            class="button button-primary text-sm md:text-base"
                          >
                            {{ $t('Views.Lobby.Sections.Program.goTo') }}
                            {{ checkMainStage.options.mainStageLabel }}
                          </router-link>
                        </div>
                      </div>
                    </Card>
                  </CollapsibleItem>
                </CollapsibleList>
              </div>
            </div>
          </div>
        </CollapsibleItem>
      </CollapsibleList>
    </template>
  </Section>
</template>

<script>
import Section from '@/components/shared/Section';
import CollapsibleList from '@/components/shared/CollapsibleList';
import CollapsibleItem from '@/components/shared/CollapsibleItem';
import Card from '@/components/shared/Card';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import bookSessions from '@/store/modules/bookSessions';
import BookSessionButton from '@/components/buttons/BookSessionButton.vue';
import { getPusherInstance } from '@/utils';
import ApiClient from '@/client';

export default {
  components: {
    Section,
    CollapsibleList,
    BookSessionButton,
    CollapsibleItem,
    Card
  },
  created() {
    getPusherInstance({ isReady: true }).bind(`agenda-change`, () => {
      this.fetchItems();
      this.filterAgendaSessions();
    });
  },
  data() {
    return {
      filter: '',
      categoryFilter: '',
      translations: {
        sort_by: this.$t('Components.Filters.sortBy'),
        categories: this.$t('Components.Filters.categories'),
        search: this.$t('Components.Filters.searchPlaceholder')
      }
    };
  },
  props: {
    caption: { type: String, default: 'Agenda' },
    tracks: Array,
    bookSessions: Array,
    initiallyActiveDate: String,
    groupItemIndex: Function,
    groupItemIsFirst: Function,
    groupItemIsLast: Function,
    searchAgendasForDateAndTime: Function,
    singleAgendaGroupedByDay: Object,
    lastDateInSingleAgenda: String,
    isLoading: Boolean,
    trackById: Function,
    goToStreamFromSession: Function,
    showGoToMainStage: Boolean,
    locale: String,
    checkMainStage: Object,
    addOrDeleteBooksSesions: Function,
    filterSessions: Function,
    agendaSessionCategories: Array,
    isExternalAgenda: Boolean,
    isBookSessionLoading: Boolean
  },
  methods: {
    ...mapActions('Info', ['setPromotedPage']),
    ...mapMutations('Agendas', ['setAgenda']),
    handleSearchChange(e) {
      this.filter = e.detail;
    },
    handleChangeCategory(e) {
      this.categoryFilter = e.detail;
    },
    filterAgendaSessions() {
      if (this.filter || this.categoryFilter) {
        this.filterSessions({
          searchText: this.filter,
          category: this.categoryFilter
        });
        return this.$store.getters['Agendas/filteredSingleAgendaGroupedByDay'];
      }
      return this.$store.getters['Agendas/singleAgendaGroupedByDay'];
    },
    fetchItems() {
      if (this.isExternalAgenda) {
        ApiClient.getIframeAgendas().then((r) => this.setAgenda(r.data));
      } else {
        ApiClient.getAgendas().then((r) => this.setAgenda(r.data));
      }
    }
  },
  computed: {
    ...mapGetters('Authentication', ['getUser']),
    ...mapGetters('CurrentEvent', ['getEvent']),

    applicablePlatform() {
      return this.$route.fullPath.includes('webapp') ? ['webApp'] : ['digital'];
    },
    isSessionPassed() {
      return (session) => {
        return this.$moment(session.endDate).isBefore(this.$moment());
      };
    },
    isLimitReached() {
      return (session) => {
        const checkUser = session.userIds.some(
          (userId) => userId === this.getUser.UserID
        );
        return (
          !checkUser &&
          session.userIds.length === session.myAgendaSize &&
          session.myAgendaSize !== 0
        );
      };
    },
    isBookingsEnabled() {
      const event = this.getEvent;
      return this.isExternalAgenda === true
        ? false
        : event.features.bookingsEnabled;
    },
    displayedItems() {
      return this.filterAgendaSessions();
    },
    totalSeatsAvailable() {
      return (session) => {
        return session.myAgendaSize - session.userIds.length;
      };
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .html-content > p:has(iframe) {
  position: relative;
  padding-top: 56.25%;
}
::v-deep .html-content > p > iframe {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  left: 0;
}
.leader-start {
  &.indent {
    left: 40px;
    position: relative;
    width: calc(100% - 50px);
    &:before {
      content: '';
      position: absolute;
      border-left: 3px;
      border-left-color: #cccccc;
      border-left-style: dashed;
      width: auto;
      height: calc(100% + 13px);
      top: -13px;
      left: -25px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: -18px;
      border-bottom: 3px;
      border-bottom-color: #cccccc;
      border-bottom-style: dashed;
      width: 18px;
      height: auto;
    }
    &.indent-first {
      &:before {
        height: calc(50% + 16px);
        top: 50%;
      }
    }
    &.indent-last {
      &:before {
        height: calc(50% + 16px);
      }
    }
    .indent-button {
      display: block;
    }
  }
}
</style>
