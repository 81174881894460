<template>
  <div id="speakers" class="mx-auto max-w-large anchor">
    <SpeakerList
      :active="true"
      :showHeader="true"
      :isStage="false"
      :listHeight="76.4"
      :stageFilterDesign="false"
    />
  </div>
</template>
<script>
import SpeakerList from '@/components/speaker/SpeakerList.vue';

export default {
  props: {
    options: Object
  },
  components: {
    SpeakerList
  }
};
</script>
